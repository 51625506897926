@import url("https://fonts.googleapis.com/css?family=Montserrat");

html,
body {
  height: 100%;
}

iframe {
  display: none;
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
a,
i,
li,
span,
button,
b,
* {
  font-family: "Poppins";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

p,
li,
b {
  font-size: 16px;
  color: #323232;
}

.text-left {
  text-align: left;
}

.text-white {
  color: white;
}

* {
  outline: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (min-width: 500px) {

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }
}