@import url(https://fonts.googleapis.com/css?family=Montserrat);
html,
body {
  height: 100%;
}

iframe {
  display: none;
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
a,
i,
li,
span,
button,
b,
* {
  font-family: "Poppins";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

p,
li,
b {
  font-size: 16px;
  color: #323232;
}

.text-left {
  text-align: left;
}

.text-white {
  color: white;
}

* {
  outline: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (min-width: 500px) {

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }
}
[custom-transition][custom-transition][custom-transition-duration="50"],
body[custom-transition-duration="50"] [custom-transition] {
    transition-duration: 50ms
}

[custom-transition][custom-transition][custom-transition-delay="50"],
body[custom-transition-delay="50"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="50"].custom-animate,
body[custom-transition-delay="50"] [custom-transition].custom-animate {
    transition-delay: 50ms
}

[custom-transition][custom-transition][custom-transition-duration="100"],
body[custom-transition-duration="100"] [custom-transition] {
    transition-duration: .1s
}

[custom-transition][custom-transition][custom-transition-delay="100"],
body[custom-transition-delay="100"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="100"].custom-animate,
body[custom-transition-delay="100"] [custom-transition].custom-animate {
    transition-delay: .1s
}

[custom-transition][custom-transition][custom-transition-duration="150"],
body[custom-transition-duration="150"] [custom-transition] {
    transition-duration: .15s
}

[custom-transition][custom-transition][custom-transition-delay="150"],
body[custom-transition-delay="150"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="150"].custom-animate,
body[custom-transition-delay="150"] [custom-transition].custom-animate {
    transition-delay: .15s
}

[custom-transition][custom-transition][custom-transition-duration="200"],
body[custom-transition-duration="200"] [custom-transition] {
    transition-duration: .2s
}

[custom-transition][custom-transition][custom-transition-delay="200"],
body[custom-transition-delay="200"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="200"].custom-animate,
body[custom-transition-delay="200"] [custom-transition].custom-animate {
    transition-delay: .2s
}

[custom-transition][custom-transition][custom-transition-duration="250"],
body[custom-transition-duration="250"] [custom-transition] {
    transition-duration: .25s
}

[custom-transition][custom-transition][custom-transition-delay="250"],
body[custom-transition-delay="250"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="250"].custom-animate,
body[custom-transition-delay="250"] [custom-transition].custom-animate {
    transition-delay: .25s
}

[custom-transition][custom-transition][custom-transition-duration="300"],
body[custom-transition-duration="300"] [custom-transition] {
    transition-duration: .3s
}

[custom-transition][custom-transition][custom-transition-delay="300"],
body[custom-transition-delay="300"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="300"].custom-animate,
body[custom-transition-delay="300"] [custom-transition].custom-animate {
    transition-delay: .3s
}

[custom-transition][custom-transition][custom-transition-duration="350"],
body[custom-transition-duration="350"] [custom-transition] {
    transition-duration: .35s
}

[custom-transition][custom-transition][custom-transition-delay="350"],
body[custom-transition-delay="350"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="350"].custom-animate,
body[custom-transition-delay="350"] [custom-transition].custom-animate {
    transition-delay: .35s
}

[custom-transition][custom-transition][custom-transition-duration="400"],
body[custom-transition-duration="400"] [custom-transition] {
    transition-duration: .4s
}

[custom-transition][custom-transition][custom-transition-delay="400"],
body[custom-transition-delay="400"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="400"].custom-animate,
body[custom-transition-delay="400"] [custom-transition].custom-animate {
    transition-delay: .4s
}

[custom-transition][custom-transition][custom-transition-duration="450"],
body[custom-transition-duration="450"] [custom-transition] {
    transition-duration: .45s
}

[custom-transition][custom-transition][custom-transition-delay="450"],
body[custom-transition-delay="450"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="450"].custom-animate,
body[custom-transition-delay="450"] [custom-transition].custom-animate {
    transition-delay: .45s
}

[custom-transition][custom-transition][custom-transition-duration="500"],
body[custom-transition-duration="500"] [custom-transition] {
    transition-duration: .5s
}

[custom-transition][custom-transition][custom-transition-delay="500"],
body[custom-transition-delay="500"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="500"].custom-animate,
body[custom-transition-delay="500"] [custom-transition].custom-animate {
    transition-delay: .5s
}

[custom-transition][custom-transition][custom-transition-duration="550"],
body[custom-transition-duration="550"] [custom-transition] {
    transition-duration: .55s
}

[custom-transition][custom-transition][custom-transition-delay="550"],
body[custom-transition-delay="550"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="550"].custom-animate,
body[custom-transition-delay="550"] [custom-transition].custom-animate {
    transition-delay: .55s
}

[custom-transition][custom-transition][custom-transition-duration="600"],
body[custom-transition-duration="600"] [custom-transition] {
    transition-duration: .6s
}

[custom-transition][custom-transition][custom-transition-delay="600"],
body[custom-transition-delay="600"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="600"].custom-animate,
body[custom-transition-delay="600"] [custom-transition].custom-animate {
    transition-delay: .6s
}

[custom-transition][custom-transition][custom-transition-duration="650"],
body[custom-transition-duration="650"] [custom-transition] {
    transition-duration: .65s
}

[custom-transition][custom-transition][custom-transition-delay="650"],
body[custom-transition-delay="650"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="650"].custom-animate,
body[custom-transition-delay="650"] [custom-transition].custom-animate {
    transition-delay: .65s
}

[custom-transition][custom-transition][custom-transition-duration="700"],
body[custom-transition-duration="700"] [custom-transition] {
    transition-duration: .7s
}

[custom-transition][custom-transition][custom-transition-delay="700"],
body[custom-transition-delay="700"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="700"].custom-animate,
body[custom-transition-delay="700"] [custom-transition].custom-animate {
    transition-delay: .7s
}

[custom-transition][custom-transition][custom-transition-duration="750"],
body[custom-transition-duration="750"] [custom-transition] {
    transition-duration: .75s
}

[custom-transition][custom-transition][custom-transition-delay="750"],
body[custom-transition-delay="750"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="750"].custom-animate,
body[custom-transition-delay="750"] [custom-transition].custom-animate {
    transition-delay: .75s
}

[custom-transition][custom-transition][custom-transition-duration="800"],
body[custom-transition-duration="800"] [custom-transition] {
    transition-duration: .8s
}

[custom-transition][custom-transition][custom-transition-delay="800"],
body[custom-transition-delay="800"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="800"].custom-animate,
body[custom-transition-delay="800"] [custom-transition].custom-animate {
    transition-delay: .8s
}

[custom-transition][custom-transition][custom-transition-duration="850"],
body[custom-transition-duration="850"] [custom-transition] {
    transition-duration: .85s
}

[custom-transition][custom-transition][custom-transition-delay="850"],
body[custom-transition-delay="850"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="850"].custom-animate,
body[custom-transition-delay="850"] [custom-transition].custom-animate {
    transition-delay: .85s
}

[custom-transition][custom-transition][custom-transition-duration="900"],
body[custom-transition-duration="900"] [custom-transition] {
    transition-duration: .9s
}

[custom-transition][custom-transition][custom-transition-delay="900"],
body[custom-transition-delay="900"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="900"].custom-animate,
body[custom-transition-delay="900"] [custom-transition].custom-animate {
    transition-delay: .9s
}

[custom-transition][custom-transition][custom-transition-duration="950"],
body[custom-transition-duration="950"] [custom-transition] {
    transition-duration: .95s
}

[custom-transition][custom-transition][custom-transition-delay="950"],
body[custom-transition-delay="950"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="950"].custom-animate,
body[custom-transition-delay="950"] [custom-transition].custom-animate {
    transition-delay: .95s
}

[custom-transition][custom-transition][custom-transition-duration="1000"],
body[custom-transition-duration="1000"] [custom-transition] {
    transition-duration: 1s
}

[custom-transition][custom-transition][custom-transition-delay="1000"],
body[custom-transition-delay="1000"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1000"].custom-animate,
body[custom-transition-delay="1000"] [custom-transition].custom-animate {
    transition-delay: 1s
}

[custom-transition][custom-transition][custom-transition-duration="1050"],
body[custom-transition-duration="1050"] [custom-transition] {
    transition-duration: 1.05s
}

[custom-transition][custom-transition][custom-transition-delay="1050"],
body[custom-transition-delay="1050"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1050"].custom-animate,
body[custom-transition-delay="1050"] [custom-transition].custom-animate {
    transition-delay: 1.05s
}

[custom-transition][custom-transition][custom-transition-duration="1100"],
body[custom-transition-duration="1100"] [custom-transition] {
    transition-duration: 1.1s
}

[custom-transition][custom-transition][custom-transition-delay="1100"],
body[custom-transition-delay="1100"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1100"].custom-animate,
body[custom-transition-delay="1100"] [custom-transition].custom-animate {
    transition-delay: 1.1s
}

[custom-transition][custom-transition][custom-transition-duration="1150"],
body[custom-transition-duration="1150"] [custom-transition] {
    transition-duration: 1.15s
}

[custom-transition][custom-transition][custom-transition-delay="1150"],
body[custom-transition-delay="1150"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1150"].custom-animate,
body[custom-transition-delay="1150"] [custom-transition].custom-animate {
    transition-delay: 1.15s
}

[custom-transition][custom-transition][custom-transition-duration="1200"],
body[custom-transition-duration="1200"] [custom-transition] {
    transition-duration: 1.2s
}

[custom-transition][custom-transition][custom-transition-delay="1200"],
body[custom-transition-delay="1200"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1200"].custom-animate,
body[custom-transition-delay="1200"] [custom-transition].custom-animate {
    transition-delay: 1.2s
}

[custom-transition][custom-transition][custom-transition-duration="1250"],
body[custom-transition-duration="1250"] [custom-transition] {
    transition-duration: 1.25s
}

[custom-transition][custom-transition][custom-transition-delay="1250"],
body[custom-transition-delay="1250"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1250"].custom-animate,
body[custom-transition-delay="1250"] [custom-transition].custom-animate {
    transition-delay: 1.25s
}

[custom-transition][custom-transition][custom-transition-duration="1300"],
body[custom-transition-duration="1300"] [custom-transition] {
    transition-duration: 1.3s
}

[custom-transition][custom-transition][custom-transition-delay="1300"],
body[custom-transition-delay="1300"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1300"].custom-animate,
body[custom-transition-delay="1300"] [custom-transition].custom-animate {
    transition-delay: 1.3s
}

[custom-transition][custom-transition][custom-transition-duration="1350"],
body[custom-transition-duration="1350"] [custom-transition] {
    transition-duration: 1.35s
}

[custom-transition][custom-transition][custom-transition-delay="1350"],
body[custom-transition-delay="1350"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1350"].custom-animate,
body[custom-transition-delay="1350"] [custom-transition].custom-animate {
    transition-delay: 1.35s
}

[custom-transition][custom-transition][custom-transition-duration="1400"],
body[custom-transition-duration="1400"] [custom-transition] {
    transition-duration: 1.4s
}

[custom-transition][custom-transition][custom-transition-delay="1400"],
body[custom-transition-delay="1400"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1400"].custom-animate,
body[custom-transition-delay="1400"] [custom-transition].custom-animate {
    transition-delay: 1.4s
}

[custom-transition][custom-transition][custom-transition-duration="1450"],
body[custom-transition-duration="1450"] [custom-transition] {
    transition-duration: 1.45s
}

[custom-transition][custom-transition][custom-transition-delay="1450"],
body[custom-transition-delay="1450"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1450"].custom-animate,
body[custom-transition-delay="1450"] [custom-transition].custom-animate {
    transition-delay: 1.45s
}

[custom-transition][custom-transition][custom-transition-duration="1500"],
body[custom-transition-duration="1500"] [custom-transition] {
    transition-duration: 1.5s
}

[custom-transition][custom-transition][custom-transition-delay="1500"],
body[custom-transition-delay="1500"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1500"].custom-animate,
body[custom-transition-delay="1500"] [custom-transition].custom-animate {
    transition-delay: 1.5s
}

[custom-transition][custom-transition][custom-transition-duration="1550"],
body[custom-transition-duration="1550"] [custom-transition] {
    transition-duration: 1.55s
}

[custom-transition][custom-transition][custom-transition-delay="1550"],
body[custom-transition-delay="1550"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1550"].custom-animate,
body[custom-transition-delay="1550"] [custom-transition].custom-animate {
    transition-delay: 1.55s
}

[custom-transition][custom-transition][custom-transition-duration="1600"],
body[custom-transition-duration="1600"] [custom-transition] {
    transition-duration: 1.6s
}

[custom-transition][custom-transition][custom-transition-delay="1600"],
body[custom-transition-delay="1600"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1600"].custom-animate,
body[custom-transition-delay="1600"] [custom-transition].custom-animate {
    transition-delay: 1.6s
}

[custom-transition][custom-transition][custom-transition-duration="1650"],
body[custom-transition-duration="1650"] [custom-transition] {
    transition-duration: 1.65s
}

[custom-transition][custom-transition][custom-transition-delay="1650"],
body[custom-transition-delay="1650"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1650"].custom-animate,
body[custom-transition-delay="1650"] [custom-transition].custom-animate {
    transition-delay: 1.65s
}

[custom-transition][custom-transition][custom-transition-duration="1700"],
body[custom-transition-duration="1700"] [custom-transition] {
    transition-duration: 1.7s
}

[custom-transition][custom-transition][custom-transition-delay="1700"],
body[custom-transition-delay="1700"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1700"].custom-animate,
body[custom-transition-delay="1700"] [custom-transition].custom-animate {
    transition-delay: 1.7s
}

[custom-transition][custom-transition][custom-transition-duration="1750"],
body[custom-transition-duration="1750"] [custom-transition] {
    transition-duration: 1.75s
}

[custom-transition][custom-transition][custom-transition-delay="1750"],
body[custom-transition-delay="1750"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1750"].custom-animate,
body[custom-transition-delay="1750"] [custom-transition].custom-animate {
    transition-delay: 1.75s
}

[custom-transition][custom-transition][custom-transition-duration="1800"],
body[custom-transition-duration="1800"] [custom-transition] {
    transition-duration: 1.8s
}

[custom-transition][custom-transition][custom-transition-delay="1800"],
body[custom-transition-delay="1800"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1800"].custom-animate,
body[custom-transition-delay="1800"] [custom-transition].custom-animate {
    transition-delay: 1.8s
}

[custom-transition][custom-transition][custom-transition-duration="1850"],
body[custom-transition-duration="1850"] [custom-transition] {
    transition-duration: 1.85s
}

[custom-transition][custom-transition][custom-transition-delay="1850"],
body[custom-transition-delay="1850"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1850"].custom-animate,
body[custom-transition-delay="1850"] [custom-transition].custom-animate {
    transition-delay: 1.85s
}

[custom-transition][custom-transition][custom-transition-duration="1900"],
body[custom-transition-duration="1900"] [custom-transition] {
    transition-duration: 1.9s
}

[custom-transition][custom-transition][custom-transition-delay="1900"],
body[custom-transition-delay="1900"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1900"].custom-animate,
body[custom-transition-delay="1900"] [custom-transition].custom-animate {
    transition-delay: 1.9s
}

[custom-transition][custom-transition][custom-transition-duration="1950"],
body[custom-transition-duration="1950"] [custom-transition] {
    transition-duration: 1.95s
}

[custom-transition][custom-transition][custom-transition-delay="1950"],
body[custom-transition-delay="1950"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="1950"].custom-animate,
body[custom-transition-delay="1950"] [custom-transition].custom-animate {
    transition-delay: 1.95s
}

[custom-transition][custom-transition][custom-transition-duration="2000"],
body[custom-transition-duration="2000"] [custom-transition] {
    transition-duration: 2s
}

[custom-transition][custom-transition][custom-transition-delay="2000"],
body[custom-transition-delay="2000"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2000"].custom-animate,
body[custom-transition-delay="2000"] [custom-transition].custom-animate {
    transition-delay: 2s
}

[custom-transition][custom-transition][custom-transition-duration="2050"],
body[custom-transition-duration="2050"] [custom-transition] {
    transition-duration: 2.05s
}

[custom-transition][custom-transition][custom-transition-delay="2050"],
body[custom-transition-delay="2050"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2050"].custom-animate,
body[custom-transition-delay="2050"] [custom-transition].custom-animate {
    transition-delay: 2.05s
}

[custom-transition][custom-transition][custom-transition-duration="2100"],
body[custom-transition-duration="2100"] [custom-transition] {
    transition-duration: 2.1s
}

[custom-transition][custom-transition][custom-transition-delay="2100"],
body[custom-transition-delay="2100"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2100"].custom-animate,
body[custom-transition-delay="2100"] [custom-transition].custom-animate {
    transition-delay: 2.1s
}

[custom-transition][custom-transition][custom-transition-duration="2150"],
body[custom-transition-duration="2150"] [custom-transition] {
    transition-duration: 2.15s
}

[custom-transition][custom-transition][custom-transition-delay="2150"],
body[custom-transition-delay="2150"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2150"].custom-animate,
body[custom-transition-delay="2150"] [custom-transition].custom-animate {
    transition-delay: 2.15s
}

[custom-transition][custom-transition][custom-transition-duration="2200"],
body[custom-transition-duration="2200"] [custom-transition] {
    transition-duration: 2.2s
}

[custom-transition][custom-transition][custom-transition-delay="2200"],
body[custom-transition-delay="2200"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2200"].custom-animate,
body[custom-transition-delay="2200"] [custom-transition].custom-animate {
    transition-delay: 2.2s
}

[custom-transition][custom-transition][custom-transition-duration="2250"],
body[custom-transition-duration="2250"] [custom-transition] {
    transition-duration: 2.25s
}

[custom-transition][custom-transition][custom-transition-delay="2250"],
body[custom-transition-delay="2250"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2250"].custom-animate,
body[custom-transition-delay="2250"] [custom-transition].custom-animate {
    transition-delay: 2.25s
}

[custom-transition][custom-transition][custom-transition-duration="2300"],
body[custom-transition-duration="2300"] [custom-transition] {
    transition-duration: 2.3s
}

[custom-transition][custom-transition][custom-transition-delay="2300"],
body[custom-transition-delay="2300"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2300"].custom-animate,
body[custom-transition-delay="2300"] [custom-transition].custom-animate {
    transition-delay: 2.3s
}

[custom-transition][custom-transition][custom-transition-duration="2350"],
body[custom-transition-duration="2350"] [custom-transition] {
    transition-duration: 2.35s
}

[custom-transition][custom-transition][custom-transition-delay="2350"],
body[custom-transition-delay="2350"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2350"].custom-animate,
body[custom-transition-delay="2350"] [custom-transition].custom-animate {
    transition-delay: 2.35s
}

[custom-transition][custom-transition][custom-transition-duration="2400"],
body[custom-transition-duration="2400"] [custom-transition] {
    transition-duration: 2.4s
}

[custom-transition][custom-transition][custom-transition-delay="2400"],
body[custom-transition-delay="2400"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2400"].custom-animate,
body[custom-transition-delay="2400"] [custom-transition].custom-animate {
    transition-delay: 2.4s
}

[custom-transition][custom-transition][custom-transition-duration="2450"],
body[custom-transition-duration="2450"] [custom-transition] {
    transition-duration: 2.45s
}

[custom-transition][custom-transition][custom-transition-delay="2450"],
body[custom-transition-delay="2450"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2450"].custom-animate,
body[custom-transition-delay="2450"] [custom-transition].custom-animate {
    transition-delay: 2.45s
}

[custom-transition][custom-transition][custom-transition-duration="2500"],
body[custom-transition-duration="2500"] [custom-transition] {
    transition-duration: 2.5s
}

[custom-transition][custom-transition][custom-transition-delay="2500"],
body[custom-transition-delay="2500"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2500"].custom-animate,
body[custom-transition-delay="2500"] [custom-transition].custom-animate {
    transition-delay: 2.5s
}

[custom-transition][custom-transition][custom-transition-duration="2550"],
body[custom-transition-duration="2550"] [custom-transition] {
    transition-duration: 2.55s
}

[custom-transition][custom-transition][custom-transition-delay="2550"],
body[custom-transition-delay="2550"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2550"].custom-animate,
body[custom-transition-delay="2550"] [custom-transition].custom-animate {
    transition-delay: 2.55s
}

[custom-transition][custom-transition][custom-transition-duration="2600"],
body[custom-transition-duration="2600"] [custom-transition] {
    transition-duration: 2.6s
}

[custom-transition][custom-transition][custom-transition-delay="2600"],
body[custom-transition-delay="2600"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2600"].custom-animate,
body[custom-transition-delay="2600"] [custom-transition].custom-animate {
    transition-delay: 2.6s
}

[custom-transition][custom-transition][custom-transition-duration="2650"],
body[custom-transition-duration="2650"] [custom-transition] {
    transition-duration: 2.65s
}

[custom-transition][custom-transition][custom-transition-delay="2650"],
body[custom-transition-delay="2650"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2650"].custom-animate,
body[custom-transition-delay="2650"] [custom-transition].custom-animate {
    transition-delay: 2.65s
}

[custom-transition][custom-transition][custom-transition-duration="2700"],
body[custom-transition-duration="2700"] [custom-transition] {
    transition-duration: 2.7s
}

[custom-transition][custom-transition][custom-transition-delay="2700"],
body[custom-transition-delay="2700"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2700"].custom-animate,
body[custom-transition-delay="2700"] [custom-transition].custom-animate {
    transition-delay: 2.7s
}

[custom-transition][custom-transition][custom-transition-duration="2750"],
body[custom-transition-duration="2750"] [custom-transition] {
    transition-duration: 2.75s
}

[custom-transition][custom-transition][custom-transition-delay="2750"],
body[custom-transition-delay="2750"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2750"].custom-animate,
body[custom-transition-delay="2750"] [custom-transition].custom-animate {
    transition-delay: 2.75s
}

[custom-transition][custom-transition][custom-transition-duration="2800"],
body[custom-transition-duration="2800"] [custom-transition] {
    transition-duration: 2.8s
}

[custom-transition][custom-transition][custom-transition-delay="2800"],
body[custom-transition-delay="2800"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2800"].custom-animate,
body[custom-transition-delay="2800"] [custom-transition].custom-animate {
    transition-delay: 2.8s
}

[custom-transition][custom-transition][custom-transition-duration="2850"],
body[custom-transition-duration="2850"] [custom-transition] {
    transition-duration: 2.85s
}

[custom-transition][custom-transition][custom-transition-delay="2850"],
body[custom-transition-delay="2850"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2850"].custom-animate,
body[custom-transition-delay="2850"] [custom-transition].custom-animate {
    transition-delay: 2.85s
}

[custom-transition][custom-transition][custom-transition-duration="2900"],
body[custom-transition-duration="2900"] [custom-transition] {
    transition-duration: 2.9s
}

[custom-transition][custom-transition][custom-transition-delay="2900"],
body[custom-transition-delay="2900"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2900"].custom-animate,
body[custom-transition-delay="2900"] [custom-transition].custom-animate {
    transition-delay: 2.9s
}

[custom-transition][custom-transition][custom-transition-duration="2950"],
body[custom-transition-duration="2950"] [custom-transition] {
    transition-duration: 2.95s
}

[custom-transition][custom-transition][custom-transition-delay="2950"],
body[custom-transition-delay="2950"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="2950"].custom-animate,
body[custom-transition-delay="2950"] [custom-transition].custom-animate {
    transition-delay: 2.95s
}

[custom-transition][custom-transition][custom-transition-duration="3000"],
body[custom-transition-duration="3000"] [custom-transition] {
    transition-duration: 3s
}

[custom-transition][custom-transition][custom-transition-delay="3000"],
body[custom-transition-delay="3000"] [custom-transition] {
    transition-delay: 0
}

[custom-transition][custom-transition][custom-transition-delay="3000"].custom-animate,
body[custom-transition-delay="3000"] [custom-transition].custom-animate {
    transition-delay: 3s
}

[custom-transition][custom-transition][custom-transition-easing=linear],
body[custom-transition-easing=linear] [custom-transition] {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[custom-transition][custom-transition][custom-transition-easing=ease],
body[custom-transition-easing=ease] [custom-transition] {
    transition-timing-function: ease
}

[custom-transition][custom-transition][custom-transition-easing=ease-in],
body[custom-transition-easing=ease-in] [custom-transition] {
    transition-timing-function: ease-in
}

[custom-transition][custom-transition][custom-transition-easing=ease-out],
body[custom-transition-easing=ease-out] [custom-transition] {
    transition-timing-function: ease-out
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-out],
body[custom-transition-easing=ease-in-out] [custom-transition] {
    transition-timing-function: ease-in-out
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-back],
body[custom-transition-easing=ease-in-back] [custom-transition] {
    transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[custom-transition][custom-transition][custom-transition-easing=ease-out-back],
body[custom-transition-easing=ease-out-back] [custom-transition] {
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-out-back],
body[custom-transition-easing=ease-in-out-back] [custom-transition] {
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-sine],
body[custom-transition-easing=ease-in-sine] [custom-transition] {
    transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[custom-transition][custom-transition][custom-transition-easing=ease-out-sine],
body[custom-transition-easing=ease-out-sine] [custom-transition] {
    transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-out-sine],
body[custom-transition-easing=ease-in-out-sine] [custom-transition] {
    transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-quad],
body[custom-transition-easing=ease-in-quad] [custom-transition] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[custom-transition][custom-transition][custom-transition-easing=ease-out-quad],
body[custom-transition-easing=ease-out-quad] [custom-transition] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-out-quad],
body[custom-transition-easing=ease-in-out-quad] [custom-transition] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-cubic],
body[custom-transition-easing=ease-in-cubic] [custom-transition] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[custom-transition][custom-transition][custom-transition-easing=ease-out-cubic],
body[custom-transition-easing=ease-out-cubic] [custom-transition] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-out-cubic],
body[custom-transition-easing=ease-in-out-cubic] [custom-transition] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-quart],
body[custom-transition-easing=ease-in-quart] [custom-transition] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[custom-transition][custom-transition][custom-transition-easing=ease-out-quart],
body[custom-transition-easing=ease-out-quart] [custom-transition] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[custom-transition][custom-transition][custom-transition-easing=ease-in-out-quart],
body[custom-transition-easing=ease-in-out-quart] [custom-transition] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[custom-transition^=fade][custom-transition^=fade] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform
}

[custom-transition^=fade][custom-transition^=fade].custom-animate {
    opacity: 1;
    -webkit-transform: translateZ(0);
            transform: translateZ(0)
}

[custom-transition=fade-up] {
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0)
}

[custom-transition=fade-down] {
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0)
}

[custom-transition=fade-right] {
    -webkit-transform: translate3d(-100px, 0, 0);
            transform: translate3d(-100px, 0, 0)
}

[custom-transition=fade-left] {
    -webkit-transform: translate3d(100px, 0, 0);
            transform: translate3d(100px, 0, 0)
}

[custom-transition=fade-up-right] {
    -webkit-transform: translate3d(-100px, 100px, 0);
            transform: translate3d(-100px, 100px, 0)
}

[custom-transition=fade-up-left] {
    -webkit-transform: translate3d(100px, 100px, 0);
            transform: translate3d(100px, 100px, 0)
}

[custom-transition=fade-down-right] {
    -webkit-transform: translate3d(-100px, -100px, 0);
            transform: translate3d(-100px, -100px, 0)
}

[custom-transition=fade-down-left] {
    -webkit-transform: translate3d(100px, -100px, 0);
            transform: translate3d(100px, -100px, 0)
}

[custom-transition^=zoom][custom-transition^=zoom] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform
}

[custom-transition^=zoom][custom-transition^=zoom].custom-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1)
}

[custom-transition=zoom-in] {
    -webkit-transform: scale(.6);
            transform: scale(.6)
}

[custom-transition=zoom-in-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(.6);
            transform: translate3d(0, 100px, 0) scale(.6)
}

[custom-transition=zoom-in-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(.6);
            transform: translate3d(0, -100px, 0) scale(.6)
}

[custom-transition=zoom-in-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(.6);
            transform: translate3d(-100px, 0, 0) scale(.6)
}

[custom-transition=zoom-in-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(.6);
            transform: translate3d(100px, 0, 0) scale(.6)
}

[custom-transition=zoom-out] {
    -webkit-transform: scale(1.2);
            transform: scale(1.2)
}

[custom-transition=zoom-out-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
            transform: translate3d(0, 100px, 0) scale(1.2)
}

[custom-transition=zoom-out-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
            transform: translate3d(0, -100px, 0) scale(1.2)
}

[custom-transition=zoom-out-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
            transform: translate3d(-100px, 0, 0) scale(1.2)
}

[custom-transition=zoom-out-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
            transform: translate3d(100px, 0, 0) scale(1.2)
}

[custom-transition^=slide][custom-transition^=slide] {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
}

[custom-transition^=slide][custom-transition^=slide].custom-animate {
    -webkit-transform: translateZ(0);
            transform: translateZ(0)
}

[custom-transition=slide-up] {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0)
}

[custom-transition=slide-down] {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0)
}

[custom-transition=slide-right] {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0)
}

[custom-transition=slide-left] {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0)
}

[custom-transition^=flip][custom-transition^=flip] {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform
}

[custom-transition=flip-left] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
            transform: perspective(2500px) rotateY(-100deg)
}

[custom-transition=flip-left].custom-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
            transform: perspective(2500px) rotateY(0)
}

[custom-transition=flip-right] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
            transform: perspective(2500px) rotateY(100deg)
}

[custom-transition=flip-right].custom-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
            transform: perspective(2500px) rotateY(0)
}

[custom-transition=flip-up] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
            transform: perspective(2500px) rotateX(-100deg)
}

[custom-transition=flip-up].custom-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
            transform: perspective(2500px) rotateX(0)
}

[custom-transition=flip-down] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
            transform: perspective(2500px) rotateX(100deg)
}

[custom-transition=flip-down].custom-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
            transform: perspective(2500px) rotateX(0)
}
.rotate {
    -webkit-animation: rotation 5s infinite linear;
            animation: rotation 5s infinite linear;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}
